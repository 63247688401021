import React from "react"
import { Link } from "gatsby"

import FeatureIcon from "./featureicon"

import { unslugify } from '../../utils/text'
import { classNames } from "../../utils/tailwind"

// list all features
const FeatureList = ({features}) => {

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-px">
      {features.map((feature, featureIdx) => (
        <div
          key={feature.slug}
          className={classNames(
            featureIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
            featureIdx === 1 ? 'sm:rounded-tr-lg' : '',
            featureIdx === features.length - 2 ? 'sm:rounded-bl-lg' : '',
            featureIdx === features.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
            'relative group flex bg-white mb-4'
          )}
        >
          <div className="mr-2">
            <span
              className={`bg-yellow-100 text-yellow-500 rounded-lg inline-flex p-4 ring-4 ring-white`}
            >
              <FeatureIcon feature={feature.slug} className="h-6 w-6"/>
            </span>
          </div>
          <div>
            <h3 className="text-base mt-1 font-medium mb-0">
              <Link to={`/features/${feature.slug}/`} className="focus:outline-none">
                {/* Extend touch target to entire panel */}
                <span className="absolute inset-0" aria-hidden="true" />
                <span>{unslugify(feature.slug)}</span>
                <span className="ml-2 font-semibold opacity-50 text-xs">{feature.count}</span>
              </Link>
            </h3>
            <p className="mt-1 text-xs text-gray-500 mr-4">
              Online communities {feature.sentenceEnding}
            </p>
          </div>
          
        </div>
      ))}
    </div>
      
  )
}

export default FeatureList;

