import React from "react"
import { graphql, Link } from "gatsby"

import { PlusSmIcon } from '@heroicons/react/solid'

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import FeatureList from "../components/feature/featurelist"

const FeaturesPage = ({data}) => (
  <Layout pageTitle="Features of Online Communities" enableShare={true} >
    <SEO title="Features | Online Community Features" description="Each community on Hive Index is tagged with the unique features of that community. Browse online communities by the features you are looking for in a community."
      generateImage={true} path={'/features/'}
    />
    
    
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-200" />
      </div>
      <div className="relative flex items-center justify-between">
        <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">About community features on the Hive Index</h2>
      </div>
    </div>

    <div className="mt-2 text-gray-500">
      Along with topics and basic information, each community added to the Hive Index is tagged with the following features.
      We have found they are commonly found amongst online communities.
      These tags are for the purpose of making it easy to find an online community that is best suited for you.
      This list of features grows from time to time. If you feel like there is a new feature we should add, please suggest it.
    </div>

    <div className="relative mt-8">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-200" />
      </div>
      <div className="relative flex items-center justify-between">
        <h2 className="pr-3 bg-white text-xl font-medium text-gray-900 flex items-center">
          <div>All Community Features</div>
        </h2>

        <Link to="/suggest/">
          <button
            type="button"
            className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
          >
            <PlusSmIcon className="-ml-1.5 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
            <span>Suggest</span>
          </button>
        </Link>
      </div>
    </div>
    <div className="mt-2">
      <FeatureList features={data.features.nodes} />
    </div>
  </Layout>
)

export const query = graphql`
query {
  features: allGoogleSpreadsheetSourceFeatures(sort: {fields: count, order: DESC}) {
    totalCount
    nodes {
      slug
      name
      order
      sentenceEnding
      count
      icon
      iconActive
      prefix
    }
  }
}
`

export default FeaturesPage
